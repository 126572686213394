<template>
  <div class="root">
    <div :style="bgStyle" style="padding: 16px 0px 16px 16px;background-size:cover;
  background-repeat:no-repeat;">
      <div  class="info">
        <div style="display: flex">
          <div style="width: 50%">
            <div style="width: 102px;height: 27px" :style="logoStyle">
            </div>
          </div>
          <div class="cardstatus">
            <div class="cardstatustext">
              <div v-if="simCard.success">{{ simCard.statusName }}</div>
            </div>
          </div>
        </div>
        <div style="margin-top: 10px">
          <div class="device-name">
            {{ simCard.deviceName }}
          </div>
          <div class="real-name-status">
            {{ realNameStatusName }}
          </div>
        </div>
        <div style="margin-top: 16px">

          <van-row type="flex" >
            <van-col span="8">
              <div class="flow-item">
                <div class="flow-name">套餐流量</div>
                <div v-if="simCard.success" class="flow-content">{{ simCard.pkgFlow }}<span class="flow-unit">M</span></div>
              </div>
            </van-col>
            <van-col span="8">
              <div class="flow-item">
                <div class="flow-name">已用流量</div>
                <div v-if="simCard.success" class="flow-content">{{ simCard.useFlow }}<span class="flow-unit">M</span></div>
              </div>
            </van-col>
            <van-col span="8">
              <div class="flow-item">
                <div class="flow-name">剩余流量</div>
                <div v-if="simCard.success" class="flow-content">{{simCard.balanceFlow }}<span class="flow-unit">M</span></div>
              </div>
            </van-col>
          </van-row>

        </div>
        <div style="margin-top: 16px">

          <van-row type="flex" v-if="simCard.pkgVoice != null">
            <van-col span="8">
              <div class="flow-item">
                <div class="flow-name">套餐语音</div>
                <div v-if="simCard.success" class="flow-content">{{ simCard.pkgVoice }}<span class="flow-unit">分钟</span></div>
              </div>
            </van-col>
            <van-col span="8">
              <div class="flow-item">
                <div class="flow-name">已用语音</div>
                <div v-if="simCard.success" class="flow-content">{{ simCard.useVoice }}<span class="flow-unit">分钟</span></div>
              </div>
            </van-col>
            <van-col span="8">
              <div class="flow-item">
                <div class="flow-name">剩余语音</div>
                <div v-if="simCard.success" class="flow-content">{{simCard.balanceVoice }}<span class="flow-unit">分钟</span></div>
              </div>
            </van-col>
          </van-row>

        </div>

        <div class="simcard-info">
          <div>
            卡号 {{ simCard.cardNo }} <van-button v-if="simCard.cardNo"  @click="copyCardNo"  type="default" size="mini">复制</van-button>
          </div>
          <div>
            ICCID {{ simCard.iccid }} <van-button v-if="simCard.iccid"  @click="copyIccid"  type="default" size="mini">复制</van-button>
          </div>
          <div>
            有效期至 {{ simCard.expireTimeStr }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="simCard.success" class="operate">
      <div v-if="simCard.realNameStatus == 0 && simCard.status != 1" @click="goRealName" :style="button1Style"></div>
      <div v-if="simCard.openRecharge && simCard.status != 1" @click="() => {goSimCardRecharge(1)}" :style="button2Style"></div>
      <div v-if="simCard.openRecharge && simCard.status != 1" @click="() => {goSimCardRecharge(2)}" :style="button3Style"></div>
    </div>
    <div v-else-if="simCard.iccid" class="operate2">
      <div style="color: red">
        该SIM卡不支持接口查询
      </div>
    </div>
  </div>
</template>

<script>
import {Button, Col, Row, Toast,Dialog} from "vant";
import {setToken} from "@/util/auth";
import {setSaasId, getImei,setImei} from "@/util/session-storage-utils";
import {getRealNameUrl, getSimCard} from "../../api/simcard";
export default {
  name: "simcard-index",
  components:{
    [Row.name]:Row,
    [Col.name]:Col,
    [Button.name]:Button
  },
  data(){
    return {
      backgroundImage:require("../../assets/image/simcard/background1.png"),
      logoImage:require("../../assets/image/simcard/logo1.png"),
      simCard:{}
    }
  },
  methods:{
    loadData(){
      getSimCard(getImei()).then(res => {
        this.simCard = res.data
      })
    },
    goRealName(){
      getRealNameUrl(getImei()).then(res => {
        window.location.href=res.data;
      })
    },
    goSimCardRecharge(type){
      if(this.simCard.status == 5){
        //沉默期,不支持充值
        Dialog.alert({
          message: '沉默期状态不支持充值,请激活使用SIM卡后一段时间后再试',
        }).then(() => {
          // on close
        });
        return
      }
      let renewListJSONStr
      if(type ==1){
        renewListJSONStr = JSON.stringify([{name:'续费',type:4}])
      }else{
        renewListJSONStr = JSON.stringify([{name:'充值',type:5}])
      }

      window.wx.miniProgram.navigateTo({url:`/pages/setting/device/renew/main?imei=` + getImei() + "&renewListJSONStr=" + renewListJSONStr})
    },
    copyIccid(){
      this.copySpecialTopicUrl(this.simCard.iccid,() => {
        Toast.success({message:"复制成功",position:"top"})
      })
    },
    copyCardNo(){
      this.copySpecialTopicUrl(this.simCard.cardNo,() => {
        Toast.success({message:"复制成功",position:"top"})
      })
    },
    copySpecialTopicUrl (value,succCallback) {
      let oInput = document.createElement('input'); //创建一个隐藏input（重要！）
      oInput.value = value;    //赋值
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = 'oInput';
      oInput.style.display = 'none';
      if(succCallback){
        succCallback(oInput.value)
      }
    }
  },
  created(){
    setToken(this.$route.query._token)
    let imei = this.$route.query._imei;
    setImei(imei)
    let saasId = this.$route.query._saasId;
    setSaasId(saasId)
    // this.loadData();
    // this.loadData();
  },
  computed:{
    realNameStatusName(){
      let realNameStatus = this.simCard.realNameStatus
      return realNameStatus==1?"已实名":realNameStatus==0?"未实名":""
    },
    bgStyle(){
      return {
        backgroundImage: "url('" + this.backgroundImage + "')"
      }
    },
    logoStyle(){
      return {
        backgroundImage: "url('" + this.logoImage + "')"
      }
    },
    button1Style(){
      return {
        backgroundImage: "url('" + require("../../assets/image/simcard/button1.png") + "')"
      }
    },
    button2Style(){
      return {
        backgroundImage: "url('" + require("../../assets/image/simcard/button2.png") + "')"
      }
    },
    button3Style(){
      return {
        backgroundImage: "url('" + require("../../assets/image/simcard/button3.png") + "')"
      }
    }
  },
  activated() {
    window.console.log("simcard activated")
    this.loadData();
  }
}
</script>
<style>
body {
  display: block;
  margin: 0px !important;
}
</style>
<style scoped>
.info{
  /*height: 234px;*/
  width: 100%;
  background-size:cover;
  background-repeat:no-repeat;
  /*background-image:url("../../assets/image/simcard/background1.png");*/
  /*padding: 16px;*/
}
.root {
  padding: 0px;
}
.cardstatus{
  width: 50%;
  display: flex;
  align-items: center;
  justify-content:flex-end;
}
.cardstatustext{
  width: 76px;
  height: 25px;
  background: #386DF2;
  border-radius: 100px 0px 0px 100px;
  font-size: 12px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content:center;
}
.device-name{
  height: 28px;
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #212121;
  line-height: 28px;
}
.real-name-status{
  text-align: left;
  height: 17px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3385FF;
  line-height: 17px;
}
.flow-item{
  flex-direction:column;
  display: flex;
  align-items: flex-start;
  justify-content:center;
}
.flow-name{
  width: 48px;
  height: 17px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #212121;
  line-height: 17px;
}
.flow-content{
  width: 54px;
  height: 28px;
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #212121;
  line-height: 28px;
}
.flow-unit{
  width: 11px;
  height: 17px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #212121;
  line-height: 17px;
}
.simcard-info{
  margin: 16px 0px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1D232E;
  line-height: 17px;
}
.simcard-info div{
  padding: 2px 0px;
}
.operate{
  width:100%;
  height: 50px;
  background: #386DF2;
  border-radius: 0px 0px 6px 6px;
  display: flex;
  align-items: center;
  justify-content:flex-end;
}
.operate div{
  width: 72px;
  height: 24px;
  background-size:cover;
  background-repeat:no-repeat;
  margin-right: 10px;
}
.operate2{
  width:100%;
  height: 50px;
  background: #386DF2;
  border-radius: 0px 0px 6px 6px;
  display: flex;
  align-items: center;
  justify-content:center;
  font-size: 24px;
}
</style>
